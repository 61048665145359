<template>
  <div class="navigation-bar">
    <transition name="fade">
      <div v-if="showBackdrop" class="backdrop" @click="activeNavItem = null"></div>
    </transition>
    <div class="navigation">
      <a href="/" class="nav-logo">
        <img :src="site.logo ?? '/my/itrielt-logo.svg'" alt="Логотип ITRielt"/>
      </a>
      <div v-if="$store.getters.getRoles.length > 1 || $store.getters.getProfile.role === 'admin'" class="nav-item role" @click="changeRoleClick">
        <div class="nav-icon" style="mask: url('/my/menu/ambassador.svg') no-repeat center / contain"></div>
        <span class="nav-label">{{$t('Сменить')}}<br>{{$t('роль')}}</span>
      </div>
      <div class="nav-item" :class="[{'active': (activeNavItem === category.slug) || (currentCategory === category.slug)}, category.slug]"
           v-if="!isEmptyItems(category.slug) || !isEmpty(category.path)"
           v-for="category in categories" :key="category.slug" @click="openCategory(category)">
        <div class="nav-icon" :style="'mask: url(' + category.iconSrc + ') no-repeat center / contain;'"></div>
        <span class="nav-label">{{$t(category.label || '')}}</span>
      </div>
      <div class="nav-separator"></div>
      <div class="nav-item coins" v-if="coinsVisible" @click="clickCoins">
        <div class="nav-icon" :style="'mask: url(/my/menu/nav-coins.svg) no-repeat center / contain;'"></div>
        <span class="nav-label">{{ coinFormat(profile.coin || 0) }}</span>
      </div>
      <div class="nav-item switcher-theme">
        <switcher-theme/>
      </div>
      <div class="nav-item my-link" @click="clickMyLink" v-if="showMyLink">
        <div class="nav-icon" :style="'mask: url(/my/menu/qr.svg) no-repeat center / contain;'"></div>
        <span class="nav-label">{{$t('МояСсылка')}}</span>
      </div>
      <div class="nav-item support" @click="clickSupport" v-if="showMyLink">
        <div class="nav-icon" :style="'mask: url(/my/menu/nav-support.svg) no-repeat center / contain;'"></div>
        <span class="nav-label">{{$t('Поддержка')}}</span>
        <div class="chat-indicator-message">
          <indicator-counter
              v-if="$root.chatIndicatorValue"
              type="contrast"
              size="medium"
              appearance="negative"
              :counter="$root.chatIndicatorValue"
          />
        </div>
      </div>
      <div class="nav-item logout" @click="logout">
        <div class="nav-icon" style="mask: url('/my/menu/SignOut.svg') no-repeat center / contain"></div>
        <span class="nav-label">{{$t('Выйти')}}</span>
      </div>
      <div :class="'nav-item nav-expand' + (activeNavItem === 'nav-expand' ? ' active' : '')" @click="openCategory({slug: 'nav-expand'})">
        <div class="nav-icon" :style="'mask: url(/my/menu/nav-dots.svg) no-repeat center / contain;'"></div>
        <span class="nav-label">{{$t('Меню')}}</span>
      </div>
    </div>
    <transition name="fade">
      <div v-if="showBackdrop && routes.length > 0" class="navigation-tiles">
        <div class="nav-tile" v-for="route in routes" @click="closeCategory(route.path)" @click.middle="openItemNewTab(route.path)">
          <div class="tile-info">
            <div class="tile-info-title">{{ $t(route.meta.label || '') }}</div>
            <div class="tile-info-description">{{ route.meta.description ?? '' }}</div>
          </div>
          <div class="tile-icon" :style="'mask: url(/my/menu/' + route.meta.icon + '.svg) no-repeat center / contain;'"></div>
        </div>
      </div>
    </transition>
    <vue-bottom-sheet-vue2 class="nav-bottom-sheet" ref="navMobileModal" @closed="onBottomSheetClosed"
                           overlay-color="#17171C66" :max-width="10000" :z-index="4">
      <div v-if="activeNavItem === null || activeNavItem === 'nav-expand'" class="nav-bottom-container">
        <div class="nav-bottom-header">
          <div class="nav-bottom-title">
            <div>{{$t('Меню')}}</div>
            <div><switcher-theme/></div>
          </div>
          <div class="nav-bottom-icon" style="mask: url('/my/menu/SignOut.svg') no-repeat center / contain" @click="logout"></div>
        </div>
        <div class="nav-bottom-badges">
          <div class="nav-badge-role" @click="changeRoleClick">{{ getTextRole(role, profile.subrole) }}</div>
          <div class="nav-badge-coins" @click="clickCoins" v-if="coinsVisible">{{ coinFormat(profile.coin || 0) }}</div>
        </div>
        <div class="navigation-mobile">
          <div class="nav-item-mobile" :class="category.slug" v-for="category in categories" @click="activeNavItem = category.slug" v-if="!isEmptyItems(category.slug)">
            <div class="nav-item-mobile-icon"
                 :style="'mask: url(' + category.iconSrc + ') no-repeat center / contain;'"></div>
            <div class="nav-item-mobile-title">{{ $t(category.label || '') }}</div>
          </div>
          <div class="nav-item-mobile" @click="clickMyLink" v-if="showMyLink">
            <div class="nav-item-mobile-icon"
                 :style="'mask: url(/my/menu/qr.svg) no-repeat center / contain;'"></div>
            <div class="nav-item-mobile-title">{{$t('МояСсылка')}}</div>
          </div>
        </div>
        <div class="nav-bottom-separator"></div>
      </div>
      <div v-else class="nav-bottom-container">
        <div class="nav-bottom-header tiles">
          <div class="nav-bottom-icon" :style="'mask: url(/my/menu/nav-arrow-back.svg) no-repeat center / contain;'"
          @click="activeNavItem = null"></div>
          <div class="nav-bottom-title">{{ $t(currentCategoryName || '') }}</div>
        </div>
        <div class="navigation-tiles-mobile">
          <div class="nav-tile" v-for="route in routes" @click="closeCategory(route.path)">
            <div class="tile-info">
              <div class="tile-info-title">{{ $t(route.meta.label || '') }}</div>
              <div class="tile-info-description">{{ route.meta.description ?? '' }}</div>
            </div>
            <div class="tile-icon"
                 :style="'mask: url(/my/menu/' + route.meta.icon + '.svg) no-repeat center / contain;'"></div>
          </div>
        </div>
        <div class="nav-bottom-separator"></div>
      </div>
    </vue-bottom-sheet-vue2>
  </div>
</template>

<script>
import {NAVIGATION_CATEGORIES} from "@/router/navigationCategories";
import {isEmpty} from "@/utils";
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";
import SwitcherTheme from "@/components/redesign/switcherTheme.vue";
import getTextRole from "@/mixins/getTextRole";
import IndicatorCounter from "@/components/redesign/IndicatorCounter.vue";
const MIN_WIDTH_FOR_BOTTOM_MODAL = 1090
export default {
  name: "navigation",
  components: {
    IndicatorCounter,
    SwitcherTheme,
    VueBottomSheetVue2
  },
  mixins: [getTextRole],
  data() {
    return {
      activeNavItem: null,
      isNavMobileModalOpen: false,
      currentCategory: null
    }
  },
  props: ['menus', 'site'],
  computed: {
    changeActiveCategory() {
      if (this.$route.meta.category) {
        this.currentCategory = this.$route.meta.category
      }
    },
    categories() {
      return NAVIGATION_CATEGORIES
    },
    showBackdrop() {
      return !isEmpty(this.activeNavItem)
    },
    routes() {
      return this.getRoutes(this.activeNavItem)
    },
    profile () {
      return this.$store.getters.getProfile
    },
    role () {
      return this.$store.getters.getRole
    },
    coinsVisible() {
      // return this.$store.getters.getCurrentDomain === 'demo' || (this.role === 'admin' && this.$store.getters.getCurrentDomain === 'ava')
      return this.$store.getters.getCurrentDomain === 'demo' || this.$store.getters.getProfile.menu?.shop
    },
    currentCategoryName() {
      let index = this.categories.findIndex((item) => item.slug === this.activeNavItem)

      if (index === -1) {
        return ''
      }

      return this.categories[index].label
    },
    isUserProfileEmpty() {
      return (this.role == 'user') && (this.profile.responsible === null && this.site.domain === 'ava' || !this.profile.director_lastname || !this.profile.director_name  || !this.profile.city)
    },
    hostName() {
      return document.location.protocol+'//'+document.location.host
    },
    includeSubdomainsRoute() {
      return (this.role == 'admin') && (this.hostName==='https://building.letsbot.ru' || this.hostName==='http://localhost:8080' || this.hostName==='http://localhost:8081')
    },
    showMyLink() {
      return this.role === 'partner' || this.role === 'partners' || this.role === 'user' || this.role === 'bitrix' || this.role === 'support' || this.role === 'admin';
    }
  },
  methods: {
    isEmpty,
    openItemNewTab(path) {
      const routeData = this.$router.resolve({path: path});
      console.log(routeData)
      window.open(routeData.href, '_blank');
    },
    coinFormat(value) {
      if(!value) return '0'
      var formatter = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || 0
    },
    // getRoleSlug(role, subrole) {
    //   switch (role){
    //     case 'admin':
    //       return this.$i18n.t('Администратор')
    //     case 'bank':
    //       return this.$i18n.t('РедакторБанк')
    //     case 'tests':
    //       return this.$i18n.t('РедакторОбучение')
    //     case 'bitrix':
    //       return this.$i18n.t('Менеджер')
    //     case 'client':
    //       return this.$i18n.t('Клиент')
    //     case 'marketing':
    //       return this.$i18n.t('РедакторМаркетинг')
    //     case 'sales':
    //       return this.$i18n.t('РедакторПродажи')
    //     case 'tech':
    //       return this.$i18n.t('РедакторТех')
    //     case 'user':
    //       if(subrole ==='leader') return this.$i18n.t('Агентство')
    //       return this.$i18n.t('Агент')
    //     case 'agency':
    //       return this.$i18n.t('Агентство')
    //     case 'partner':
    //       return this.$i18n.t('Партнер')
    //     case 'partners':
    //       return this.$i18n.t('Партнер')
    //   }
    // },
    openCategory({slug, path}) {
      $('.modal').modal('hide')

      if (this.getRoutes(slug).length === 1) {
        const path = this.getRoutes(slug)[0].path
        this.$router.push(path)

        if (window.innerWidth < MIN_WIDTH_FOR_BOTTOM_MODAL) {
          this.$refs.navMobileModal.close();
        }

        this.onBottomSheetClosed()
        return
      }

      if (this.activeNavItem === slug) {
        this.onBottomSheetClosed()

        if (window.innerWidth < MIN_WIDTH_FOR_BOTTOM_MODAL) {
          this.$refs.navMobileModal.close();
        }

        return
      }

      if (path) {
        this.$router.push(path)
        if (window.innerWidth < MIN_WIDTH_FOR_BOTTOM_MODAL) {
          this.$refs.navMobileModal.close();
        }

        this.onBottomSheetClosed()
        return
      }

      this.activeNavItem = slug
      if (window.innerWidth < MIN_WIDTH_FOR_BOTTOM_MODAL) {
        this.$refs.navMobileModal.open();
      }
    },
    closeCategory(path) {
      if (path) {
        this.$router.push(path ?? '/')
      }

      if (window.innerWidth < MIN_WIDTH_FOR_BOTTOM_MODAL) {
        setTimeout(() => {
          this.activeNavItem = null
        }, 400)

        this.$refs.navMobileModal.close();
        return
      }

      this.activeNavItem = null
    },
    onBottomSheetClosed() {
      this.isNavMobileModalOpen = false

      setTimeout(() => {
        this.activeNavItem = null
      }, 400)
    },
    logout() {
      this.activeNavItem = null

      if (window.innerWidth < MIN_WIDTH_FOR_BOTTOM_MODAL) {
        this.$refs.navMobileModal.close();
      }

      this.$emit('logout-click')
    },
    clickCoins() {
      this.activeNavItem = null

      if (window.innerWidth < MIN_WIDTH_FOR_BOTTOM_MODAL) {
        this.$refs.navMobileModal.close();
      }

      this.$emit('coins-click')
    },
    clickSupport() {
      this.activeNavItem = null

      if (window.innerWidth < MIN_WIDTH_FOR_BOTTOM_MODAL) {
        this.$refs.navMobileModal.close();
      }

      this.$emit('support-click')
    },
    clickMyLink() {
      this.activeNavItem = null

      if (window.innerWidth < MIN_WIDTH_FOR_BOTTOM_MODAL) {
        this.$refs.navMobileModal.close();
      }

      this.$emit('my-link-click')
    },
    isEmptyItems(categorySlug) {
      return this.getRoutes(categorySlug).length === 0
    },
    getRoutes(slug) {
      let routes = JSON.parse(JSON.stringify(this.$router.options.routes))
      let result = []

      if (this.isUserProfileEmpty) {
        const index = routes.findIndex((route) => route.path == '/profile')
        routes = index !== -1 ? [routes[index]] : routes
      }

      routes.forEach((route) => {
        let showItem = this.menus[route.name] ?? false

        if (route.path === '/subdomains' && this.includeSubdomainsRoute) {
          showItem = true
        }

        if ((route.meta.category === slug) && showItem) {
          result.push(route)
        }
      })
      return result;
    },
    changeRoleClick() {
      this.$emit('role-click')
      this.activeNavItem = null

      if (window.innerWidth < MIN_WIDTH_FOR_BOTTOM_MODAL) {
        this.$refs.navMobileModal.close();
      }
    }
  },
  updated() {
    this.$store.dispatch('SET_NAVIGATION', this.menus)
  }
}
</script>

<style lang="scss">
$nav-item-transition: 0.3s ease;
$tile-inside-transition: 0.3s ease;
$active-color: var(--color-6F77FE);
$secondary-color: var(--colorIconSecondary);
$nav-width: 80px;
$nav-icon-side: 28px;

.navigation-bar {
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(23, 23, 28, 0.4);
    z-index: 2900;
  }

  .navigation {
    background: var(--white-basic);
    transition: background 0.5s ease, border 0.5s ease;
    z-index: 3000;
    top: 0;
    left: 0;
    bottom: 0;
    width: $nav-width;
    position: fixed;
    border-right: 0.5px solid var(--color-Stroke-Primary);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;

    .nav-separator {
      flex-grow: 1;
    }

    .nav-logo {
      padding: 25px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        padding: 0 10px;
      }
    }

    .nav-item {
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      &.active, &:hover {
        .nav-icon {
          background-color: $active-color;
        }

        .nav-label {
          color: $active-color;
        }
      }

      &.coins {
        .nav-label {
          color: rgba(255, 185, 86, 1);
        }

        .nav-icon {
          background-color: rgba(255, 185, 86, 1);
        }
      }

      &.nav-expand {
        display: none;
      }

      .nav-icon {
        display: block;
        width: $nav-icon-side;
        height: $nav-icon-side;
        background-color: $secondary-color;
        transition: background-color $nav-item-transition;
      }

      .nav-label {
        color: $secondary-color;
        margin-top: 5px;
        display: block;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.01px;
        transition: color $nav-item-transition;
        text-align: center;
      }
      &.support{
        position: relative;
        .chat-indicator-message{
          position: absolute;
          top: 0;
          right: 10px;
        }
      }
    }
  }

  .navigation-tiles, .navigation-tiles-mobile {
    position: fixed;
    padding: 16px;
    top: 0;
    left: $nav-width;
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(5, 1fr);
    z-index: 3000;

    .nav-tile {
      background-color: var(--colorBackgroundContent);
      padding: 16px;
      border-radius: var(--radius-05, 8px);
      box-shadow: 0 0 16px 0 rgba(23, 23, 28, 0.15), 0 16px 32px 0 rgba(23, 23, 28, 0.10);
      width: 240px;
      height: 160px;
      cursor: pointer;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      transition: background-color $tile-inside-transition;

      &:hover {
        background-color: $active-color;

        .tile-info {
          color: var(--colorTextPrimaryInvariably);

          .tile-info-description {
            opacity: 1;
          }
        }

        .tile-icon {
          background-color: var(--colorIconPrimaryInvariably);
        }
      }

      .tile-info {
        color: var(--colorTextPrimary);
        width: 100%;
        transition: color $tile-inside-transition;

        .tile-info-title {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.024px;
        }

        .tile-info-description {
          margin-top: 5px;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          letter-spacing: 0.01px;
          opacity: 0;
          transition: opacity $tile-inside-transition;
        }
      }

      .tile-icon {
        background-color: $active-color;
        width: $nav-icon-side;
        height: $nav-icon-side;
        transition: background-color $tile-inside-transition;
      }
    }
  }

  .nav-bottom-sheet {
    //z-index: 2000!important;

    .bottom-sheet__content {
      background-color: var(--colorBackgroundContent);
    }

    .bottom-sheet__header {
      display: none;
    }

    .nav-bottom-container {
      padding: 16px;

      .nav-bottom-separator {
        height: 70px;
      }

      .nav-bottom-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin-bottom: 12px;
        align-items: center;

        &.tiles {
          justify-content: start;

          .nav-bottom-icon {
            background-color: $active-color;
            margin-right: 16px;
          }
        }

        .nav-bottom-title {
          color: var(--colorTextPrimary);
          font-variant-numeric: lining-nums tabular-nums;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 120% */

          display: flex;
          gap: 15px;
        }

        .nav-bottom-icon {
          width: $nav-icon-side;
          height: $nav-icon-side;
          background-color: var(--colorIconPrimary);
          cursor: pointer;
        }
      }

      .nav-bottom-badges {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 12px;
        margin-bottom: 12px;

        div {
          text-align: center;
          padding: 9px;
          width: 100%;
          border-radius: var(--radius-05, 8px);
          color: #FFFFFF;
          cursor: pointer;
        }

        .nav-badge-role {
          background-color: var(--color-Accent-Gray, #B9BAC5);
        }

        .nav-badge-coins {
          background: var(--color-Accent-Gold, #FFB956);
        }
      }

      .navigation-mobile {
        .nav-item-mobile {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          &.clients, &.objects {
            display: none;
          }
          .nav-item-mobile-icon {
            width: $nav-icon-side;
            height: $nav-icon-side;
            background-color: $active-color;
            margin: 12px 16px 12px 0;
          }

          .nav-item-mobile-title {
            color: var(--colorTextPrimary);
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
            letter-spacing: 0.024px;
          }
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .navigation-bar {
    .navigation-tiles {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media(max-width: 1090px) {
  .navigation-bar {
    .backdrop {
      background-color: transparent;
    }

    .navigation-tiles-mobile {
      grid-template-columns: repeat(4, 1fr);
    }

    .navigation {
      top: unset;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
      box-shadow: 0 0 8px 0 rgba(30, 30, 36, 0.10), 0 8px 16px 0 rgba(23, 23, 28, 0.05);

      .nav-logo, .coins, .nav-separator, .profile, .manage, .dev, .logout, .role, .my-link, .switcher-theme {
        display: none;
      }

      .nav-item {
        &.nav-expand {
          display: flex;
        }
      }
    }

    .navigation-tiles-mobile {
      position: relative;
      left: 0;

      .nav-tile {
        width: 233px;
        height: 160px;
      }
    }

    .navigation-tiles {
      display: none;
    }
  }
}

@media(max-width: 768px) {
  .navigation-bar {
    .navigation-tiles-mobile {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media(max-width: 500px) {
  .navigation-bar {
    .navigation-tiles-mobile {
      display: block;
      padding: 0;

      .nav-tile {
        width: 100%;
        height: max-content;
        flex-direction: row-reverse;
        box-shadow: none;
        padding: 12px 0;
        border-radius: 0;

        &:hover {
          background-color: transparent;

          .tile-info {
            color: var(--colorTextPrimary);;

            .tile-info-description {
              opacity: 0;
            }
          }

          .tile-icon {
            background-color: $active-color;
          }
        }

        .tile-icon {
          margin-right: 16px;
        }
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>